//  @import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "boxicons";
// @import '~bootstrap/dist/css/bootstrap.css';

body {
  margin: 0;
  font-family: Poppins, Helvetica, sans-serif;
}

input:focus,
textarea:focus {
  outline: none;
}

button:focus {
  outline: none;
}

:root {
  --color-primary: 41, 59, 45;
  --color-accent: 183, 159, 128;
  --text-color-light: white;
  // --font: Product Sans;
  --font-title: "Montserrat", sans-serif;
  --text-hint: #000000;
  --navbar-height: 90px;
  --padding-gutter: 1rem;
  --section-margin-top: 120px;
  --text-color: rgba(40, 59, 42, 1);
}

.dropdown {
  position: relative;
  display: inline-block;
}

.iti--allow-dropdown input,
.iti--allow-dropdown input[type=text],
.iti--allow-dropdown input[type=tel],
.iti--separate-dial-code input,
.iti--separate-dial-code input[type=text],
.iti--separate-dial-code input[type=tel] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
  width: inherit !important;
}


.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 100%;

  li {
    padding: 12px 16px;
  }
}

.hg-dialog-panel-class {
  border-radius: 60px;
  overflow: hidden;
  z-index: 9999;
}

.hg-snackbar-success {
  @apply bg-green-default;
}

.hg-snackbar-danger {
  @apply bg-red-default;
}

@layer utilities {
  @variants responsive {
    .masonry-3-col {
      column-count: 3;
      column-gap: 1em;
    }

    .masonry-2-col {
      column-count: 2;
      column-gap: 1em;
    }

    .break-inside {
      break-inside: avoid;
    }
  }
}

html {
  scroll-behavior: smooth;
}


// ngx-intl-tel-input custom style

.iti {
  position: relative;
  display: inline-block;
  width: 100% !important;
}

.iti--allow-dropdown input,
.iti--allow-dropdown input[type=text],
.iti--allow-dropdown input[type=tel],
.iti--separate-dial-code input,
.iti--separate-dial-code input[type=text],
.iti--separate-dial-code input[type=tel] {
  margin-left: 0;
  width: inherit !important;
  padding: 12px 6px 12px 52px !important;
  border: 0.5px solid rgba(0,0,0,.12) !important;
  border-radius: 5px !important;
}
